import type {WpMedia, Settings} from "~/integration/wordpress/types";
import type {SettingsState} from "~/stores/settings";

export default () => {
    const extractShortcodeParam = (shortcode: string, paramName: string) => {
        const regex = new RegExp(paramName + "\\s*=\\s*['\"]?([^\\]'\"\\s]+)['\"]?", "i");
        const matches = shortcode.match(regex);
        if (matches && matches.length > 1) {
            return matches[1];
        } else {
            return null;
        }
    }

    const getMediaUrlFromMedia = (media: WpMedia | undefined) => {
        return media?.mediaItemUrl;
    }

    const getMediaDetailsFromMedia = (media: WpMedia | undefined) => {
        return media?.mediaDetails;
    }

    const getMediaAltFromMedia = (media: WpMedia | undefined) => {
        return media?.altText;
    }

    const getMediaWidthFromMedia = (media: WpMedia | undefined) => {
        return getMediaDetailsFromMedia(media)?.width;
    }

    const getMediaHeightFromMedia = (media: WpMedia | undefined) => {
        return getMediaDetailsFromMedia(media)?.height;
    }

    const formatPhoneToTel = (phone: string | undefined) => {
        const cleanedPhone = phone?.replace(/\D/g, '');
        return `tel:${cleanedPhone}`;
    }

    const isExternalLink = (link: string) => {
        return link.indexOf('http') > -1;
    }

    const getSettingsValue = (settings: Settings | SettingsState | undefined, name: string, kind: string = 'themeGeneral') => {
        return settings?.[kind]?.[name];
    }

    return {
        extractShortcodeParam,
        getMediaUrlFromMedia,
        getMediaDetailsFromMedia,
        getMediaAltFromMedia,
        getMediaWidthFromMedia,
        getMediaHeightFromMedia,
        formatPhoneToTel,
        isExternalLink,
        getSettingsValue
    }
}